import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'

import * as queryString from 'query-string'
import MainGrid from '../components/styling/MainGrid'

///Forms/GetInTouch/GetInTouchFormLeftContent'
import GetInTouchFormRightContent from '../components/Forms/GetInTouch/GetInTouchFormRight'
import ModalLayout from '../components/Modals/ModalLayout'
import { graphql } from 'gatsby'
import Icon from '../images/forms/dale.png'
import FormLeftContent from '../components/Forms/FormLeftContent'
import { appendUrlPartnerTracking } from '../util/url'
import Button from '../components/shared/Button/Button'
import checks from '../buildAssets/compliance/tick.svg'
import { InPageBot } from "@leadoo/react-bot";

const ContactUs = ({ location }) => {
  

  const heading = "Let’s talk!"
  const body = "Want to chat with our team, or have a question about our products? You're in the right place. Simply interact with the bot on this page, and we'll help you with whatever it is you require. Prefer a call? You can reach us at 01226 337335"
  const employeeName = "Dale"
  const employeeTitle = "Business Development Manager"
 
  /* THIS SEGMENT COMMENTED OUT IS FOR HUBSPOT IMPLEMENTATION
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const { full_name } = queryString.parse(location.search)
    const { refreshed } = queryString.parse(location.search)
        if (typeof window !== 'undefined') {
          if(refreshed===undefined &&full_name === undefined){
       
           window.location.href = window.location.href + "?refreshed=true"
    
    }
    }
  
    // console.log("fullname: " + full_name)
    if(full_name !== undefined){
      setSuccess(true)
    }else{
      setSuccess(false)
    }

  }, [])
  //console.log("success: " + success)
  let rightContent = <GetInTouchFormRightContent />
  if(success){
    rightContent= <div>
    <SuccessImage src={checks} />
    <HeadingSuccess>
      Thank you for getting in contact with us
    </HeadingSuccess>
    <SubHeadingSuccess>
      Thanks for getting in contact. A member of our team will be in touch
      to respond directly to your query.
    </SubHeadingSuccess>
    <a style={{maxWidth:165}} href={appendUrlPartnerTracking("/")}>
    <Button href={appendUrlPartnerTracking("/")}>Done</Button>
    </a>
</div>
  }*/
  var rightContent;
  if (typeof window !== 'undefined' && window.location.href.includes("bobsbusiness.co.uk")){
    rightContent = <InPageBot code="4FebtcVA" />;
  } else {
    rightContent = <InPageBot code="KFnC0xdh" />;
  }
return (

    <ModalLayout
    type={'Get In Touch'}
    body={'Body'}
    leftContent={<FormLeftContent data = {{heading:heading,body:body,employeeTitle:employeeTitle,employeeName:employeeName,icon:Icon}}/>}
    rightContent={rightContent}
    />
 
  )
}



const Tag = styled.p`
  font-size: 13px;
  line-height: 21px;
  font-weight: 400;
  > a {
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
  }
`

const HeadingSuccess = styled.h2`

  font-size: 32px;
  line-height: 40px;
`
const SubHeading = styled.h5`
  padding: 0px 40px 41px;
  /* color: ${theme.text.greyLight}; */
  font-weight: 300;
`
const SubHeadingSuccess = styled(SubHeading)`
  padding: 0px 0px 25px;
`

const SuccessImage = styled.img`
  margin-top: 35px;
  width: 90px;
  height: 90px;
  margin-bottom: 35px;
`


export default ContactUs

