import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
// import theme from '../../styling/theme'
// import MainGrid from '../../styling/MainGrid'
// import img from '../../../images/lady_laptop.svg'
// import MakeAclaimForm from '../Form/MakeAclaimForm'
import { MainGrid } from '../styling/MainGrid'
import { IconButton } from '@material-ui/core'
import $ from 'jquery'
import './modal.scss'
import { Link, navigate } from 'gatsby'
import { appendUrlPartnerTracking } from '../../util/url'
// import '../../components/styling/layout.scss'
interface ModalLayout {
  // showModal: boolean
  // toggleModal(state: boolean): void
  type: string
  body: string
  leftContent: any
  rightContent: any
}

const ModalLayout: FunctionComponent<ModalLayout> = ({
  children,
  type,
  leftContent,
  rightContent,
}) => {
  return (
    <OuterContainer>
      <Modal>
        <Top>
          <Inner>
            <p className="p-large">{type}</p>

            <IconButton
              style={{ height: 40, width: 40 }}
              onClick={() => {
                navigate(-2)
              }}
            >
              <CloseIcon style={{ height: 40, width: 40, color: '#C70F55' }} />
            </IconButton>
          </Inner>
        </Top>

        <MainGrid>
          <Content>
            <Col>{leftContent}</Col>
            <Col>{rightContent}</Col>
          </Content>
        </MainGrid>
      </Modal>
    </OuterContainer>
  )
}

export default ModalLayout

const Cross = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;

  @media (min-width: 1024px) {
    display: none;
  }
`

const Closebtn = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    width: 90px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    /* border: 1px solid ${theme.brand.blue}; */
    background: white;
    /* color: ${theme.brand.blue}; */
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      /* background: ${theme.brand.blue}; */
      color: white;
    }
  }
`

interface OuterContainerProps {
  showModal: boolean
}

const OuterContainer = styled.div<OuterContainerProps>`
  //position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;

  align-items: flex-start;
  justify-content: center;
  z-index: 55555;
  height: 100%;
  background: white;
`

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 55555;
  overflow-y: visible;
`

const Top = styled.div`
  background: white;
  border-bottom: 1px solid #ececec;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
`

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 27px 20px;

  @media only screen and (min-width: 768px) {
    padding: 3rem 3%;
  }

  & p {
    /* color: ${theme.text.main}; */
    font-weight: bold;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 32px 0 50px;
  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 6.2rem 0 10rem;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  &:first-of-type {
    margin-top: 72px;
  }

  & p {
    margin-top: 18px;
  }
  @media (min-width: 1024px) {
    width: 50%;

    &:first-of-type {
      margin-right: 5rem;
      margin-top: 0px;
    }
  }
`
const ImgMask = styled.div`
  margin-top: 25px;

  @media (min-width: 768px) {
    max-width: 50vw;
  }
  @media (min-width: 1024px) {
    margin-top: 48px;
    max-width: 100%;
  }
`
