import React, { FunctionComponent } from 'react'

import styled from 'styled-components'
import theme from '../styling/theme'

import image from '../../../buildAssets/phishing-simulations/12.png'

interface FormProps {
  data?: any
}
const FormLeftContent: FunctionComponent<FormProps> = ({
data
}) => {

  const {heading, body, employeeName, employeeTitle, icon} = data
  return (
    <TextContainer>
      <Text>
        <Heading>{heading}</Heading>

        <SubHeading>
          {body}
        </SubHeading>

        <ContactPanel>
          {employeeName !=="" ?( <ContactImg src={icon}  alt="icon" />):(null)} 
         
          <ContactInfoSection>
            <b>{employeeName}</b>
            <p >{employeeTitle}</p>
          </ContactInfoSection>
        </ContactPanel>
      </Text>
      {/* <TrustedByPanel>
        <Tag>Trusted By</Tag>
        <TrustedLogosContainer>
          <TrustedLogos src={image} />
          <TrustedLogos src={image} />
          <TrustedLogos src={image} />
          <TrustedLogos src={image} />
        </TrustedLogosContainer>
      </TrustedByPanel> */}
    </TextContainer>
  )
}

export default FormLeftContent

const TextContainer = styled.div`
  /* margin-top: 40px; */
  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 10rem;
    padding-right: 0;
  }
  @media (max-width: 767px) {
    /* margin-bottom: 38px; */
  }
`
const Text = styled.div`
  width: 100%;

  border: 1px solid ${theme.text.main};
  border-radius: 6px;
  overflow: hidden;
  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 10rem;
    margin-right: 0;
  }
  @media (max-width: 767px) {
    margin-bottom: 38px;
  }
`

const Heading = styled.h2`
  padding: 49px 40px 25px;
`
const HeadingSuccess = styled.h2`
  padding: 49px 0px 25px;
`
const SubHeading = styled.h5`
  padding: 0px 40px 41px;
  color: ${theme.text.greyLight};
  font-weight: 300;
`
const SubHeadingSuccess = styled(SubHeading)`
  padding: 0px 0px 25px;
`
const Tag = styled.p`
  margin-top: 26px;
  color: #5e7177;
`
const ContactPanel = styled.div`
  padding: 37px 38px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${theme.grey.scroll};
  background-color: ${theme.background.hpHeader};
`
const ContactImg = styled.img`
  width: 60px;
  height: 60px;
  padding: 1px;
  border: 2px solid ${theme.brand.blue};
  border-radius: 50%;
  margin-right: 20px;
`
const SuccessImage = styled.img`
  margin-top: 35px;
  width: 90px;
  height: 90px;
`
const ContactInfoSection = styled.div`
> p{
  margin-top: 0px;
}
`

const Form = styled.div`
  width: 50%;
  @media (max-width: 1023px) {
    width: 100%;
  }
`
const FormNav = styled.div`
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1023px) {
    width: 100%;
  }
`

const TrustedByPanel = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    display: none;
  }
`
const TrustedLogosContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const TrustedLogos = styled.img`
  height: 75px;
  @media (max-width: 767px) {
  }
`
